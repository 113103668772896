import React  from 'react';
import { useTranslation } from 'react-i18next';
import './Welcome.scss'

export const Welcome = () => {
    const [t] = useTranslation();

    return (
        <div className='page welcome-page'>
            <div className='logo'>
                <img src="/assets/reactvty_icon.png" />
            </div>
            <p className="subtitle">{t('welcome.subtitle')}</p>
             <div className='app-store-buttons'>
                {
                // update it in https://developer.apple.com/app-store/marketing/guidelines/
                }
                <a href="https://apps.apple.com/es/app/reactvty/id6443722163?l=es" target="_blank" >
                    <img alt='Disponible en iOS App Store' src="/assets/app-store-badge.svg" />
                </a>

                {
                // update it in https://play.google.com/intl/en_us/badges/
                }
                <a target="_blank" href='https://play.google.com/store/apps/details?id=com.reactvty.app&gl=ES&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img 
                        alt='Get it on Google Play' 
                        src='/assets/google-play-badge.png'/>
                </a>
            </div>
        </div>
    );
}