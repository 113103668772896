import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/modal/Modal";
import './NuevasFuncionalidadesLight.scss'

const getWindowSize = () => {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

interface IProps {
    isPrivacyModalOpen?: boolean;
    isToSModalOpen?: boolean;
}

export const NuevasFuncionalidadesLight = ({isPrivacyModalOpen, isToSModalOpen}:IProps) => {
    const [t] = useTranslation();
    const [openPrivacyModal, setOpenPrivacyModal] = useState(!!isPrivacyModalOpen);
    const [openToSModal, setOpenTosModal] = useState(!!isPrivacyModalOpen);

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        window.addEventListener('resize', () => setWindowSize(getWindowSize()));

        return () => {
            window.removeEventListener('resize', () => setWindowSize(getWindowSize()));
        };
    }, []);

    useEffect(() =>{
        setOpenPrivacyModal(!!isPrivacyModalOpen)
    }, [isPrivacyModalOpen])

    useEffect(() =>{
        setOpenTosModal(!!isToSModalOpen)
    }, [isToSModalOpen])

      return (
        <div className="page nuevas-funcionalidades-light-page">
            <Modal isOpen={openPrivacyModal}> 
                <h1>{t('privacy-policy.title')}</h1>
                <p>{t('privacy-policy.p-intro')}</p>
                
                <h2>{t('privacy-policy.title-responsable')}</h2>
                <p>{t('privacy-policy.p-responsable')}</p>

                <h2>{t('privacy-policy.title-purpose')}</h2>
                <p>{t('privacy-policy.p-purpose')}</p>

                <h2>{t('privacy-policy.title-legitimation')}</h2>
                <p>{t('privacy-policy.p-legitimation')}</p>

                <h2>{t('privacy-policy.title-destinataries')}</h2>
                <p>{t('privacy-policy.p-destinataries')}</p>

                <h2>{t('privacy-policy.title-rights')}</h2>
                <p>{t('privacy-policy.p-rights')}</p>

                <h2>{t('privacy-policy.title-duration')}</h2>
                <p>{t('privacy-policy.p-duration')}</p>

                <h2>{t('privacy-policy.title-communications')}</h2>
                <p>{t('privacy-policy.p-communications')}</p>

                <h2>{t('privacy-policy.title-changes')}</h2>
                <p>{t('privacy-policy.p-changes')}</p>
           </Modal>
           <Modal isOpen={openToSModal}> 
                <h1>{t('terms-of-service.title')}</h1>
                <p>{t('terms-of-service.p-intro')}</p>

                <h2>{t('terms-of-service.title-register')}</h2>
                <p>{t('terms-of-service.p-register')}</p>

                <h2>{t('terms-of-service.title-app-content')}</h2>
                <p>{t('terms-of-service.p-app-content')}</p>

                <h2>{t('terms-of-service.title-user-content')}</h2>
                <p>{t('terms-of-service.p-user-content')}</p>

                <h2>{t('terms-of-service.title-external-resources')}</h2>
                <p>{t('terms-of-service.p-external-resources')}</p>

                <h2>{t('terms-of-service.title-acceptable-usage')}</h2>
                <p>{t('terms-of-service.p-acceptable-usage')}</p>

                <h2>{t('terms-of-service.title-license')}</h2>
                <p>{t('terms-of-service.p-license')}</p>

            </Modal>

            <div className="main-content">
                <div className="balls">
                    <div style={{height: windowSize.innerWidth/10, width: windowSize.innerWidth/10}} className="ball blue"></div>
                    <div style={{height: windowSize.innerWidth/10, width: windowSize.innerWidth/10}} className="ball green"></div>
                    <div style={{height: windowSize.innerWidth/10, width: windowSize.innerWidth/10}} className="ball yellow"></div>
                    <div style={{height: windowSize.innerWidth/10, width: windowSize.innerWidth/10}} className="ball orange"></div>
                    <div style={{height: windowSize.innerWidth/10, width: windowSize.innerWidth/10}} className="ball red"></div>
                </div>
                <div className="info">
                    <h1>{t('newFeatures.title')}</h1>
                    <p>{t('newFeatures.description')}</p>
                </div>
            </div>
            <div className="footer">
                <div className="footer-contents">
                    <div className="contact">
                        <p className="contact-title" >{t('newFeatures.contact')}</p>
                        <p className="contact-email">sales@reactvty.com</p>
                    </div>
                    <div className="buttons">
                        <button onClick={() =>{setOpenPrivacyModal(!openPrivacyModal); setOpenTosModal(false)}}>{t('newFeatures.privacyPolicy')}</button>
                        <button onClick={() => {setOpenTosModal(!openToSModal); setOpenPrivacyModal(false)}}>{t('newFeatures.termsOfService')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}