import React, { useEffect, useState } from "react";
import './NavBar.scss';

const getWindowSize = () => {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

interface IProps {
    currentSection: number;
    setSection: (id: number) => void
    sectionName: string[]
}
export const NavBar = ({setSection, sectionName, currentSection}: IProps) => {

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        window.addEventListener('resize', () => setWindowSize(getWindowSize()));

        return () => {
            window.removeEventListener('resize', () => setWindowSize(getWindowSize()));
        };
    }, []);
    
    return (
        <div className="navbar" style={{color: currentSection >3 ? 'black': 'white'}}>
            {windowSize.innerWidth > 500 && 
            <div style={{
                padding: '0.5rem', 
                //backgroundColor: '#575FCC', 
            //    borderRadius: '25%'
            }}>
                <img style={{filter: 'drop-shadow(2px 4px 6px black)'}} src="/assets/badge-border.png" />
            </div>
            }

            {windowSize.innerWidth > 500 && sectionName && sectionName.length> 0 && sectionName.map((section, idx) =>{
                return (
                <div key={idx}>
                    <p onClick={() =>setSection(idx)}>{section}</p>
                    {currentSection == idx && 
                        <div className="underscore"  style={{backgroundColor: currentSection > 3 ? 'black': 'white'}}></div>
                    }
                </div>
                )
            })}
            {/*for mobile*/}
            {windowSize.innerWidth < 500 && sectionName && sectionName.length> 0 && 
                <div id="menuToggle">
                    <input type="checkbox" />
                    <span></span>
                    <span></span>
                    <span></span>
                    <ul id="menu">
                        {sectionName.map((section, idx) =>{
                            return (<a key={idx} onClick={() =>setSection(idx)}><li>{section}</li></a>);
                        })}
                    </ul>
                </div>
            }
        </div>
    );
}