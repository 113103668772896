import i18n, { StringMap, TOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import translationES from "./es/translation.json";

export const resources = {
  es: {
    translation: translationES,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "es",
  fallbackLng: "es",
  debug: true,
  interpolation: {
    escapeValue: false,
  }
});

