import React from "react";
import { useTranslation } from 'react-i18next';
import './Estadisticas.scss'

export const Estadisticas = () => {
    const [t] = useTranslation();

    return (
        <div className="page statistics-page">

            <div className="main-content">
                <div className="picture">
                    <img src='/assets/statistics-1.png'/>
                    <img src='/assets/statistics-2.png'/>
                </div>
                <div className="info">
                    <h1>{t('statistics.title')}</h1>
                    <p>{t('statistics.description')}</p>
                </div>
            </div>

            <div className="big-spot">
                
            </div>
        </div>
    );
}