import { abort } from "process";
import React, { useEffect, useRef, useState } from "react";
import './Modal.scss';

interface IProps {
    isOpen: boolean;
}

export const Modal = ({isOpen, children}: any) => {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    let position = 0

    useEffect(()=>{
        setOpen(isOpen);
    },[isOpen])

    const scrollUp = (reference: any) => {
        console.log(0,position-50)
        position = Math.max(0,position-50);
        reference.scrollTo({
            top: position,
            behavior: "smooth"}
        );
    }

    const scrollDown = (reference: any) => {
        console.log(reference.scrollHeight, position+50)
        position = Math.min(reference.scrollHeight, position+50);
        reference.scrollTo({
            top: position,
            behavior: "smooth"}
        );
    }

    return (
        <>
        {open ?
            <div className="modal">
                <div ref={ref}  className="modal-contents">
                    {children}
                </div>

                <button className="button" style={{position: 'fixed', top: '10%', right: -1, zIndex:2}}onClick={() => scrollUp(ref.current)}>&#94;</button>
                <button className="button" style={{position: 'fixed', bottom: '10%', right: -1, zIndex:2}}onClick={() => scrollDown(ref.current)}>&#8964;</button>
            </div> 
            :<div></div>
        }
        </>
    );
}