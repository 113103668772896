import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {ISlideConfig, PageSlides, SlideParallaxType} from 'react-page-slides';
import { NavBar } from './components/navbar/NavBar';
import { Estadisticas } from './slides/estadisticas/Estadisticas';
import { NuevasFuncionalidadesLight } from './slides/nuevas_funcionalidades/NuevasFuncionalidadesLight';
import { Programacion } from './slides/programacion/Programacion';
import { Reacciones } from './slides/reacciones/Reacciones';
import { Welcome } from './slides/welcome/Welcome';

type ISlideConfigWithName = ISlideConfig & {name: string}
function App() {
  const [t] = useTranslation();
  const [slideIdx, setSlideIdx] = useState(0);
  const [toSModalOpen, setToSModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  
  useEffect(() =>{
    const uri = window.location.href;
    if(uri.includes('/ToS' )) {
      setToSModalOpen(true)
      setSlideIdx(4)
    }
    if (uri.includes('/privacy-policy')) {
      setPrivacyModalOpen(true)
      setSlideIdx(4)
    }
  }, []) 
  
  const slides: ISlideConfigWithName[] = [
    {
        content: <Welcome />,
        name: t('sectionNames.welcome'),
        style: {
          backgroundColor: '#575FCC'
        }
    },
    {
        content: <Programacion />,
        name: t('sectionNames.programacion'),
        style: {
          backgroundColor: '#FABE47'
        }
    },
    {
      content: <Estadisticas />,
      name: t('sectionNames.statistics'),
      style: {
        backgroundColor: '#575FCC'
      }
  },
  {
      content: <Reacciones />,
      name: t('sectionNames.reactions'),
      style: {
        backgroundColor: '#FABE47'
      }
  },
 /* {
    content: <NuevasFuncionalidades />,
    name: t('sectionNames.newFeatures'),
    style: {
      backgroundColor: 'black'
    }
  },*/
  {
    content: <NuevasFuncionalidadesLight isPrivacyModalOpen={privacyModalOpen} isToSModalOpen={toSModalOpen}/>,
    name: t('sectionNames.newFeatures'),
    style: {
      backgroundColor: '#fff8f3ff'
    }
  },
];

  return (
    <>
    <NavBar 
      currentSection={slideIdx}
      setSection={setSlideIdx} 
      sectionName={slides.map(s => s.name)} 
    />
    <PageSlides
            enableAutoScroll={true}
            transitionSpeed={1000}
            slides={slides}
            currentSlideIndex={slideIdx}
            onChange={setSlideIdx}
            parallax={{
                offset: 0.6,
                type: SlideParallaxType.reveal
            }}
        />
      </>  
  );
}

export default App;
