import React from "react";
import { useTranslation } from "react-i18next";
import './Reacciones.scss'

export const Reacciones = () => {
    const [t] = useTranslation();

    return (
        <div className="page reacciones-page">
            <div className="main-content">
                <div className="picture">
                    <img src='/assets/reactions.png'/>
                </div>
                <div className="info">
                    <h1>{t('reactions.title')}</h1>
                    <p>{t('reactions.description')}</p>
                </div>
            </div>
            <div className="lines">
                <div className="solid-line"></div>
                <div className="border-only-line"></div>
                <div className="solid-line"></div>
            </div>
        </div>
    );
}